
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import { analytics } from '../../../modules/analytics/analytics';
import { HomeLandingPageTemplate } from '../../../modules/template-pages/home-landing-template';
import { LANDING_TEMPLATE_NAMES, OPERATORS, } from '../../../modules/util/constants';
import { HomeServerData, obtainGetStaticHomeProps, } from '../../../modules/util/utilities/get-static-home-props';
import useTranslation from 'next-translate/useTranslation';
import { BaseServerExtraData, LandingPageBaseProps, } from '../../../modules/util/landing-props';
import { AcquisitionKeys } from '../../../modules/template-pages/acquisition-template';
import { getStaticPaths } from '../../../modules/util/utilities/get-static-paths';
const withI18n = (Component) => (props) => {
    const i18n = useTranslation();
    return <Component i18n={i18n} {...props}/>;
};
// Wrap the HomeLandingPageTemplate with the HOC
const HomeLandingPageTemplateWithI18n = withI18n(HomeLandingPageTemplate);
const HomeLandingActivePhonePage = (props: LandingPageBaseProps<HomeServerData<BaseServerExtraData, AcquisitionKeys>>) => {
    const { t, lang } = useTranslation();
    return (<HomeLandingPageTemplateWithI18n isAnnounced={false} operator={OPERATORS.CUBACEL} i18n={{ t, lang }} promo={true} analytics={analytics} {...props}/>);
};
export default HomeLandingActivePhonePage;
const getStaticProps = obtainGetStaticHomeProps(OPERATORS.CUBACEL, LANDING_TEMPLATE_NAMES.ACTIVE_PROMO_PHONE_ACQUISITION, LANDING_TEMPLATE_NAMES.NO_PROMO_PHONE_ACQUISITION);
export { getStaticPaths };

    async function __Next_Translate__getStaticProps__19174a9b001__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/fonoma/home_landing_active_phone/[[...timeestamp]]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19174a9b001__ as getStaticProps }
  